import axios from "axios";
import { CommonTexts as Texts} from "../helpers/LayoutTexts";
import { LoginModel } from "../models/LoginModel";

export async function postAdminLogin(loginModel: LoginModel) {
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "admins/login", loginModel)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function postAdminLogout(adminId: number, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "admins/logout?adminId=" + adminId, {}, {
            headers: {
                'Authorization' : `Bearer ${token}`
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}
