import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { StyleContext } from "../providers/StyleContextProvider";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Login } from "../components/login/Login";
import { Header } from "../components/shared/Header";
import { useAlert } from "../hooks/useAlert";

export const LoginPage: React.FC = () => {    
    let params = useParams();
    const stage = params.stage;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();  
    const styleContext = useContext(StyleContext);    
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("loginPage"));    
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });    
    const callbackUrl: string | null = searchParams.get("callbackUrl");
    const message: string | null = searchParams.get("message"); 
    const {Alert, openAlert} = useAlert();

    useEffect(() => { 
        window.scrollTo(0, 0);   
        setStyles(styleContext.getComponentStyle("loginPage"));                                                
    }, [isMobile]);

    const handleChangeState = (stage: string) => {
        if(callbackUrl !== null) {
          searchParams.set("callbackUrl", callbackUrl);
        }
        if(message !== null) {
          searchParams.set("message", message);
        }
        navigate("/admin/" + stage + `?${searchParams}`);
    }

    return (
        <>
            <Header />            
            <div style={styles.mainWrapper}>
                <div style={styles.leftWrapper}></div>
                <div style={styles.rightWrapper}>
                    {stage === "login" && <>
                        <Login styles={styles} callbackUrl={callbackUrl} message={message} changeStage={handleChangeState} openAlert={openAlert} />
                    </>}
                </div>          
            </div>            
            <Alert />
        </>        
    )
}