import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { StyleContext } from "../providers/StyleContextProvider";
import { ReactComponent as WhatsApp } from "../assets/svgs/whatsapp-logo.svg";
import { ReactComponent as Teachers } from "../assets/svgs/teachers.svg";
import { ReactComponent as Degrees } from "../assets/svgs/degrees.svg";
import { ReactComponent as Payments } from "../assets/svgs/payments.svg";
import { ReactComponent as OnlineClasses } from "../assets/svgs/online-class.svg";
import { useNavigate } from "react-router-dom";
import { LoggedAdmin } from "../models/LoggedAdmin";
import { AuthContext } from "../providers/AuthContextProvider";
import { Header } from "../components/shared/Header";

export const HomePage: React.FC = () => {
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const authContext = useContext(AuthContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("homePage"));  
    const admin: LoggedAdmin | null = authContext.getLoggedAdmin();     
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });  

    useEffect(() => {                        
        if(admin === null) {            
            navigate('/admin/login');
        }  
    });

    useEffect(() => {  
        window.scrollTo(0, 0);                                     
    }, []);

    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("homePage")); 
    }, [isMobile]);

    return (   
        <div style={styles}>
            <Header />
            <div style={styles.wrapper}>
                <div style={styles.rowWrapper}>
                    <div style={styles.card} onClick={() => navigate('/whatsapp-manager')}>                            
                        <WhatsApp style={styles.card.icon} />
                        <span style={styles.card.name}>{"WhatsApp Manager"}</span>                       
                    </div> 
                    <div style={styles.card} onClick={() => navigate('/teachers')}>                            
                        <Teachers style={styles.card.icon} />
                        <span style={styles.card.name}>{"Teachers"}</span>                       
                    </div>  
                    <div style={styles.card} onClick={() => navigate('/degrees')}>                            
                        <Degrees style={styles.card.icon} />
                        <span style={styles.card.name}>{"Degrees"}</span>                       
                    </div>    
                    <div style={styles.card} onClick={() => navigate('/payments')}>                            
                        <Payments style={styles.card.icon} />
                        <span style={styles.card.name}>{"Payments"}</span>                       
                    </div>       
                    <div style={styles.card} onClick={() => navigate('/online-classes/weeks')}>                            
                        <OnlineClasses style={styles.card.icon} />
                        <span style={styles.card.name}>{"Online Classes"}</span>                       
                    </div>                                
                </div>
            </div>           
        </div>                    
    )
}