import React, { useEffect, useRef } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker.entry';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

interface Props {
    pdfUrl: string;
    styles: any;
    handleClick?(): void;
}

const PDFViewer: React.FC<Props> = (props) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const loadPDF = async () => {
            const loadingTask = pdfjsLib.getDocument(props.pdfUrl);
            const pdf = await loadingTask.promise;
            const page = await pdf.getPage(1); // Displaying the first page as an example

            const scale = 1.5;
            const viewport = page.getViewport({ scale });
            const canvas = canvasRef.current;
            const context = canvas?.getContext('2d');

            if (canvas && context) {
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                const renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };
                page.render(renderContext);
            }
        };
        loadPDF();
    }, [props.pdfUrl]);

    return (
        <div style={props.styles} onClick={props.handleClick}>
            <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }}></canvas>
        </div>
    );
};

export default PDFViewer;