export enum Color {
    Blue = "#01A9E0",
    White = "#FFFFFF",
    Black = "#000000",    
    DeepBlack = "#0B132B",
    Green = "#00A7A9", 
    AquaGreen = "#00FFFF",    
    DarkGreen = "#53797A", 
    LightGreen = "#C2EDB0",
    MediumGreen = "#51CB20",
    SharpGreen = "#DCF5D2", 
    Gossip = "#DCF8C6",    
    Ash = "#EAEAEB",    
    LightAsh = "#F4F4F4",
    MiddleAsh = "#D5D5D6",    
    DeepAsh = "#515251", 
    SharpAsh = "#818080",   
    HarvestGold = "#E39500",
    LightGold = "#E3950019",
    MaastrichtBlue = "#0B132B",
    AliceBlue = "#EBFAFF",
    ChathamsBlue = "#164773",
    BlueDianne = "#1E5959",
    OxfordBlue = "#012E40",
    OldLace = "#FDF3E0",
    Crimson = "#DC143C",
    LoaderBackground = "#F3F3F3",
    LoaderForeground = "#ECEBEb",
    Red = "#FB3640",
    Linen = "#FEEAEB",
    Nyanza = "#E4F7DD",
    Tiber = "#0B2B40",
    Viridian = "#3B8C6E",
    EveningSea = "#025940",
    Grape = "#38184C",
    LemonCurry = "#CC8D1A",
    Castro = "#520120",
    LightGray = "#D5D5D5",
    PhilippineGray = "#909090"
}