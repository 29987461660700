import React from 'react';
import { LoggedAdmin } from '../models/LoggedAdmin';
import CryptoJS from 'crypto-js';

interface Props {
    children: React.ReactNode;
}

interface AuthContextType {  
    setLoggedAdmin(loggedAdmin: any, token?: string): void;      
    getLoggedAdmin(): any;   
}

export const AuthContext = React.createContext<AuthContextType>({  
    setLoggedAdmin: () => {},          
    getLoggedAdmin: () => {}    
});

export const AuthContextProvider: React.FC<Props> = (props) => {             

    const setAdmin = (loggedAdmin: any, token?: string) => {
        const adminString = JSON.stringify(loggedAdmin);
        const encrypedAdminString = CryptoJS.AES.encrypt(adminString, "").toString();
        localStorage.setItem("admin", encrypedAdminString);
        if(token) {
            localStorage.setItem("token", token);
        }
    }

    const getAdmin = (): LoggedAdmin | null => {           
        const adminString = localStorage.getItem("admin");          
        if(adminString !== null) {  
            const decryptedAdminString = CryptoJS.AES.decrypt(adminString, "").toString(CryptoJS.enc.Utf8);
            const admin: LoggedAdmin = JSON.parse(decryptedAdminString);
            admin.Token = localStorage.getItem("token")!;
            return admin;
        } 
        else {
            return null;
        }       
    }

    return (
        <AuthContext.Provider value={{   
            setLoggedAdmin: setAdmin,                     
            getLoggedAdmin: getAdmin          
        }}>           
            {props.children}
        </AuthContext.Provider>
    );
}