import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../providers/AuthContextProvider";
import { LoggedAdmin } from "../../models/LoggedAdmin";

interface Props {
    styles: any;
}

export const Title: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const admin: LoggedAdmin | null = authContext.getLoggedAdmin();

    const toHome = () => {
        if(admin !== null) {
            navigate('/');
        }
    }

    return (
        <div style={props.styles} onClick={toHome}>
            TuteClass
        </div>
    );
}