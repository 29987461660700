import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import { Title } from './Title';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import { StyleContext } from '../../providers/StyleContextProvider';
import { ReactComponent as MobileMenu } from '../../assets/svgs/mobile_menu.svg';
import { MobileMenuDrawer } from './MobileMenuDrawer';
import { ProfileEntrance } from './ProfileEntrance';
import { DesktopMenu } from './DesktopMenu';


export const Header: React.FC = () => { 
    const styleContext = useContext(StyleContext);    
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("header"));                     
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [mobileMenuToggle, setMobileMenuToggle] = useState<boolean>(false);

    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("header"));                    
    }, [isMobile]);
    
    const menuItems: any[] = [        
        <Link to={'/'} style={styles.label}>{"Home"}</Link>,
        <Link to={'/government-degrees'} style={styles.label}>{"Degrees"}</Link>,  
        <Link to={'/government-diplomas'} style={styles.label}>{"Diplomas"}</Link>,
        <Link to={'/teachers'} style={styles.label}>{"Tuition Teachers"}</Link>,
        <Link to={'/online-classes/weeks'} style={styles.label}>{"Online Classes"}</Link>,
        <Link to={'/payments'} style={styles.label}>{"Payments"}</Link>
    ]    

    const handleMobileMenuToggle = () => {        
        setMobileMenuToggle((prev) => !prev);
    }

    return (
        <div style={styles.wrapper}>
            {/* This is loading only mobile platform */}
            <MobileMenuDrawer open={mobileMenuToggle} toggle={handleMobileMenuToggle}/>            
            <div style={styles.row}>                
                <Title styles={styles.title}/>  
                {/* This is loading only desktop platform */}
                <DesktopMenu styles={styles.desktopMenu} menuItems={menuItems}/>
                {!isMobile && <ProfileEntrance/>}                
                <Button type="button" style={styles.mobileMenuButton} onClick={handleMobileMenuToggle}><MobileMenu/></Button>
            </div>           
        </div>
    );
}