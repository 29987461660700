import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { StyleContext } from "../../providers/StyleContextProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoggedAdmin } from "../../models/LoggedAdmin";
import { AuthContext } from "../../providers/AuthContextProvider";
import { Footer } from "../../components/shared/Footer";
import { Button } from "../../components/shared/Button";
import { deleteTeacherAd, getTeacherAdDetails, getTeacherAdSearchOptions, getTeacherAdsCount, postTeacherAdSearch } from "../../services/TeacherService";
import { TeacherAdsCount } from "../../models/AdsCount";
import { AdminAdSearchModel } from "../../models/AdminAdSearchModel";
import { Select } from "../../components/shared/Select";
import { Option } from "../../models/Option";
import { TeacherAd } from "../../models/TeacherAd";
import { AdminAdSearchOptions } from "../../models/AdminAdSearchOptions";
import { Pagination } from "../../components/shared/Pagination";
import { ReactComponent as Delete } from "../../assets/svgs/bin.svg";
import { ReactComponent as Edit } from "../../assets/svgs/edit.svg";
import { ReactComponent as View } from "../../assets/svgs/eye-open.svg";
import { PopUpDialog } from "../../components/shared/dialogs/PopUpDialog";
import { DetailLoader } from "../../components/loaders/DetailLoader";
import { NoData } from "../../components/shared/NoData";
import { Header } from "../../components/shared/Header";

export const TeachersPage: React.FC = () => {
    const [searchParams] = useSearchParams(); 
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const authContext = useContext(AuthContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("teachersPage"));  
    const admin: LoggedAdmin | null = authContext.getLoggedAdmin();  
    const [adsCount, setAdsCount] = useState<TeacherAdsCount>(); 
    const [status, setStatus] = useState<string>("LIVE");
    const page: number = searchParams.get("page") === null ? 1 : +searchParams.get("page")!;
    const [totalPages, setTotalPages] = useState<number>();       
    const pageSize: number = 40;
    const [teachers, setTeachers] = useState<Option[]>([]);
    const teacherId: string | undefined = searchParams.get("teacherId") === null ? undefined : searchParams.get("teacherId")!;
    const [subjects, setSubjects] = useState<Option[]>([]);
    const subjectId: string | undefined = searchParams.get("subjectId") === null ? undefined : searchParams.get("subjectId")!;
    const [classTypes, setClassTypes] = useState<Option[]>([]);
    const classTypeId: string | undefined = searchParams.get("classTypeId") === null ? undefined : searchParams.get("classTypeId")!;
    const [districts, setDistricts] = useState<Option[]>([]);
    const districtId: string | undefined = searchParams.get("districtId") === null ? undefined : searchParams.get("districtId")!;
    const [optionLoading, setOptionLoading] = useState<boolean>(true);  
    const [teacherAds, setTeacherAds] = useState<TeacherAd[]>();
    const [adLoading, setAdLoading] = useState<boolean>(true);    
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [adViewLoading, setAdViewLoading] = useState<boolean>(true);
    const [openViewDialog, setOpenViewDialog] = useState<boolean>(false);
    const [adView, setAdView] = useState<TeacherAd>();  
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false); 
    const [adDelete, setAdDelete] = useState<TeacherAd>();  

    useEffect(() => {  
        if(admin === null) {            
            navigate('/admin/login');
        }
        getAdsCount();
        getSearchOptions();
        window.scrollTo(0, 0);                                     
    }, []);

    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("teachersPage")); 
    }, [isMobile]);

    useEffect(() => {
        postAdSearch();
        window.scrollTo(0, 0);                                     
    }, [page, status, teacherId, subjectId, classTypeId, districtId]);

    async function getAdsCount() {                
        await getTeacherAdsCount()
        .then((teacherAdsCount: TeacherAdsCount) => {            
            setAdsCount(teacherAdsCount);                   
        })
        .catch((error) => {
            // toErrorPage(error);
        });                            
    }

    async function getSearchOptions() {
        await getTeacherAdSearchOptions()
        .then((searchOptions: AdminAdSearchOptions) => {  
            setTeachers(searchOptions.Teachers);                      
            setSubjects(searchOptions.Subjects);            
            setClassTypes(searchOptions.ClassTypes);
            setDistricts(searchOptions.Districts);                       
            setOptionLoading(false);            
        })
        .catch((error) => {
            // toErrorPage(error);
        });
    }

    async function postAdSearch() {        
        let searchModel: AdminAdSearchModel = {
            PageNo: page,
            RecordsPerPage: pageSize,
            Status: status,
	        TeacherId: teacherId,
            SubjectId: subjectId,
            ClassTypeId: classTypeId,
            DistrictId: districtId
        }
        await postTeacherAdSearch(searchModel)
        .then((teacherAdList: TeacherAd[]) => {
            setTeacherAds(teacherAdList);            
            if(teacherAdList.length > 0) { 
                let totalPageCount = Math.ceil(teacherAdList[0].RecordCount / pageSize);            
                setTotalPages(totalPageCount);                
            }  
            else {
                setTotalPages(undefined);
            }  
            setAdLoading(false);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });                       
    }

    const navigateToNewAds = () => {
        navigate('/teachers/newAds');
    }

    async function openAdView(adId: number) {
        setOpenViewDialog(true);
        setAdViewLoading(true);
        await getTeacherAdDetails(adId)
        .then((teacherAdView: TeacherAd) => {
            setAdView(teacherAdView);               
            setAdViewLoading(false);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });
    }

    const openDeleteView = (ad: TeacherAd) => {
        setAdDelete(ad);
        setOpenDeleteDialog(true);
    }

    async function deleteAd() {
        await deleteTeacherAd(adDelete!.TeacherAdId)
        .then((success: boolean) => {
            setOpenDeleteDialog(false);
            getAdsCount();
            postAdSearch();
        })
        .catch((error) => {            
            //toErrorPage(error);
        });
    }

    const navigateToEditAds = (adId: number) => {
        navigate('/teachers/editAds/' + adId);
    }

    const handlePagination = (pageNumber: number) => {
        searchParams.set("page", pageNumber.toString());                     
        const newUrl = `?${searchParams}`;
        navigate(newUrl);        
    }

    const setTeacherId = (selectedTeacherId: string) => {
        if(selectedTeacherId) {
            searchParams.set("page", "1");
            searchParams.set("teacherId", selectedTeacherId);
        }
        else {
            searchParams.delete("teacherId");
        }
        const newUrl = `?${searchParams}`;
        navigate(newUrl);        
    }

    const setSubjectId = (selectedSubjectId: string) => {        
        if(selectedSubjectId) {
            searchParams.set("page", "1");
            searchParams.set("subjectId", selectedSubjectId);
        }
        else {
            searchParams.delete("subjectId");
        }                   
        const newUrl = `?${searchParams}`;
        navigate(newUrl);        
    }

    const setClassTypeId = (selectedClassTypeId: string) => {        
        if(selectedClassTypeId) {
            searchParams.set("page", "1");
            searchParams.set("classTypeId", selectedClassTypeId);
        }
        else {
            searchParams.delete("classTypeId");
        }                     
        const newUrl = `?${searchParams}`;
        navigate(newUrl);        
    }

    const setDistrictId = (selectedDistrictId: string) => {        
        if(selectedDistrictId) {
            searchParams.set("page", "1");
            searchParams.set("districtId", selectedDistrictId);
        }
        else {
            searchParams.delete("districtId");
        }                     
        const newUrl = `?${searchParams}`;
        navigate(newUrl);        
    }

    return (   
        <>
            <div style={styles}>
                <Header />
                <h1 style={styles.heading}>{"Tuition Teachers"}</h1>
                {adsCount && <div style={styles.buttonsWrapper}>
                    <Button 
                        style={{...styles.statusButton, ...styles.statusButton.all, ...(status === 'ALL' && styles.statusButton.active)}}
                        onClick={() => setStatus('ALL')}
                    >
                        {"All " + adsCount.AllCount}
                    </Button>
                    <Button 
                        style={{...styles.statusButton, ...styles.statusButton.live, ...(status === 'LIVE' && styles.statusButton.active)}}
                        onClick={() => setStatus('LIVE')}
                    >
                        {"Live " + adsCount.LiveCount}
                    </Button>
                    <Button 
                        style={{...styles.statusButton, ...styles.statusButton.pending, ...(status === 'PENDING' && styles.statusButton.active)}}
                        onClick={() => setStatus('PENDING')}
                    >
                        {"Pending " + adsCount.PendingCount}
                    </Button>
                    <Button 
                        style={{...styles.statusButton, ...styles.statusButton.unpaid, ...(status === 'UNPAID' && styles.statusButton.active)}}
                        onClick={() => setStatus('UNPAID')}
                    >
                        {"Unpaid " + adsCount.UnpaidCount}
                    </Button>
                    <Button 
                        style={{...styles.statusButton, ...styles.statusButton.expired, ...(status === 'EXPIRED' && styles.statusButton.active)}}
                        onClick={() => setStatus('EXPIRED')}
                    >
                        {"Expired " + adsCount.ExpiredCount}
                    </Button>
                    <Button style={styles.newAdButton} onClick={navigateToNewAds}>{"New Ads"}</Button>
                </div>}
                {!optionLoading && <div style={styles.searchWrapper}>
                    <Select 
                        styles={styles.searchSelect}
                        label={"Teachers"} 
                        options={teachers} 
                        initialValue={teacherId}
                        getSelectedValue={setTeacherId} 
                    />
                    <Select 
                        styles={styles.searchSelect}
                        label={"Subjects"} 
                        options={subjects} 
                        initialValue={subjectId}
                        getSelectedValue={setSubjectId} 
                    />
                    <Select 
                        styles={styles.searchSelect}
                        label={"Class Types"} 
                        options={classTypes} 
                        initialValue={classTypeId}
                        getSelectedValue={setClassTypeId} 
                    />
                    <Select 
                        styles={styles.searchSelect}
                        label={"Districts"} 
                        options={districts} 
                        initialValue={districtId}
                        getSelectedValue={setDistrictId} 
                    />
                </div>}
                {teacherAds && <>
                    {(teacherAds.length === 0) ? <NoData/> :
                    <table style={styles.table}>
                        <thead>
                            <tr style={styles.table.header}> 
                                <th style={styles.table.idColumn}>{"Id"}</th>                           
                                <th style={styles.table.imageColumn}>{"Image"}</th>
                                <th style={styles.table.nameColumn}>{"Name"}</th> 
                                <th style={styles.table.subjectColumn}>{"Subjects"}</th>
                                <th style={styles.table.typeColumn}>{"Class Types"}</th>
                                <th style={styles.table.districtColumn}>{"Districts"}</th>
                                <th style={styles.table.actionColumn}>{"Action"}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {teacherAds.map((teacherAd, rowIndex) => (
                                <tr key={rowIndex} style={styles.table.tr}>
                                    <td>{teacherAd.TeacherAdId}</td>
                                    <td><img style={styles.table.image} src={teacherAd.TeacherAdUrl} /></td>
                                    <td>
                                        {teacherAd.TeacherName}<br/>
                                        {teacherAd.WhatsappNo}
                                    </td>
                                    <td>{teacherAd.Subjects}</td>
                                    <td>{teacherAd.ClassTypes}</td>
                                    <td>{teacherAd.Districts}</td>
                                    <td style={styles.table.actionCell}>
                                        <div style={styles.table.iconWrapper}>
                                            <span style={styles.table.action} onClick={() => openAdView(teacherAd.TeacherAdId)}><View style={{...styles.table.icon, ...styles.table.icon.view}}/>{"View"}</span>
                                            <span style={styles.table.action} onClick={() => navigateToEditAds(teacherAd.TeacherAdId)}><Edit style={{...styles.table.icon, ...styles.table.icon.edit}}/>{"Edit"}</span>
                                            <span style={styles.table.action} onClick={() => openDeleteView(teacherAd)}><Delete style={{...styles.table.icon, ...styles.table.icon.delete}}/>{"Delete"}</span>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>}
                    {(totalPages! > 1) && <Pagination page={page} totalPages={totalPages!} handlePagination={handlePagination}/>}
                </>}
            </div>     
            <PopUpDialog styles={styles.viewDialog} open={openViewDialog} toggle={() => setOpenViewDialog(!openViewDialog)}>
                <>                
                    {adViewLoading ? <DetailLoader/> :                                
                    <div style={styles.adView.container}>                        
                        <div style={styles.adView.detailContainer}>
                            <div style={styles.adView.id}>{"Teacher Ad Id: " + adView?.TeacherAdId}</div>
                            <div style={styles.name}>
                                {adView?.TeacherName}                                    
                            </div> 
                            <div style={styles.tepNo}>
                                {adView?.WhatsappNo}                                                          
                            </div>
                            <div>
                                <div style={styles.adView.label}>{'Subjects'}</div>
                                <div style={styles.adView.value}>{adView?.Subjects}</div>
                            </div>                    
                            <div>
                                <div style={styles.adView.label}>{'Grades'}</div>                            
                                <div style={styles.adView.value}>{adView?.Grades ? adView?.Grades : "-"}</div>
                            </div>
                            <div>
                                <div style={styles.adView.label}>{'Mediums'}</div>                            
                                <div style={styles.adView.value}>{adView?.Mediums ? adView?.Mediums : "-"}</div>
                            </div>
                            <div>
                                <div style={styles.adView.label}>{'Class Types'}</div>                            
                                <div style={styles.adView.value}>{adView?.ClassTypes ? adView?.ClassTypes : "-"}</div>
                            </div>
                            <div>
                                <div style={styles.adView.label}>{'Districts'}</div>                            
                                <div style={styles.adView.value}>{adView?.Districts ? adView?.Districts : "-"}</div>
                            </div>
                            <div>
                                <div style={styles.adView.label}>{'Towns'}</div>                            
                                <div style={styles.adView.value}>{adView?.Towns ? adView?.Towns : "-"}</div>
                            </div>
                        </div>
                        <img src={adView?.TeacherAdUrl} style={styles.adView.image}></img> 
                    </div>}
                </>
            </PopUpDialog>
            <PopUpDialog styles={styles.deleteDialog} open={openDeleteDialog} toggle={() => setOpenDeleteDialog(!openDeleteDialog)}>
                <div style={styles.deleteView.text}>{adDelete?.TeacherName + " ad will permanently delete."}</div>
                <Button style={styles.deleteView.button} type="button" onClick={deleteAd}>{"Delete"}</Button> 
            </PopUpDialog>
        </>         
    )
}