import axios from "axios";
import { CommonTexts as Texts} from "../helpers/LayoutTexts";
import { OnlineClassWeekSearchCriteria } from "../models/OnlineClassWeekSearchCriteria";

export async function getClassWeeks(searchModel: OnlineClassWeekSearchCriteria, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "admins/search-online-class-weeks", {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            params: searchModel
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function saveClassWeekChanges(formData: FormData, weekId: number, adminId: number, token: string) {
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "admins/save-week-changes?adminId=" + adminId + "&weekId=" + weekId, formData, {
            headers: {                
                'Authorization' : `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}