import { dialog } from "./Dialog";
import { dropdown } from "./Dropdown";
import { footer } from "./Footer";
import { header } from "./Header";
import { homePage } from "./HomePage";
import { input } from "./Input";
import { loginPage } from "./LoginPage";
import { mobileMenu } from "./MobileMenu";
import { newAds } from "./NewAds";
import { noData } from "./NoData";
import { onlineClassWeeksPage } from "./OnlineClassWeeksPage";
import { pagination } from "./Pagination";
import { paymentsPage } from "./PaymentsPage";
import { profileEntrance } from "./ProfileEntrance";
import { responseAlert } from "./ResponseAlert";
import { select } from "./Select";
import { teachersPage } from "./TeachersPage";
import { whatsAppPage } from "./WhatsAppPage";

export const styleDictionary = new Map<string, any>();

styleDictionary.set("input", input);
styleDictionary.set("dialog", dialog);
styleDictionary.set("header", header);
styleDictionary.set("footer", footer);
styleDictionary.set("noData", noData);
styleDictionary.set("select", select);
styleDictionary.set("pagination", pagination);
styleDictionary.set("dropdown", dropdown);
styleDictionary.set("profileEntrance", profileEntrance);
styleDictionary.set("mobileMenu", mobileMenu);
styleDictionary.set("responseAlert", responseAlert);
styleDictionary.set("homePage", homePage);
styleDictionary.set("loginPage", loginPage);
styleDictionary.set("whatsAppPage", whatsAppPage);
styleDictionary.set("teachersPage", teachersPage);
styleDictionary.set("newAds", newAds);
styleDictionary.set("paymentsPage", paymentsPage);
styleDictionary.set("onlineClassWeeksPage", onlineClassWeeksPage);