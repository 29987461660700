import ContentLoader from "react-content-loader";
import { useMediaQuery } from "react-responsive";
import { merge } from "react-merge";
import { Color } from "../../enums/Color";

const loaderStyles = {
    mobile: {
        loginForm: {
            width: "90.34vw",
            backgroundColor: Color.White,
            padding: "0 4.83vw",
            marginTop: "14.49vw"
        },
        buttonContainer: {
            height: "9.66vw",
            marginBottom: "7.25vw",
            borderBottom: "2px solid" + Color.LoaderBackground
        },
        itemContainer: {
            height: "115.70vw"
        }
    },
    desktop: {
        loginForm: {
            width: "29.89vw",
            borderRadius: "0.52vw",
            border: `2px solid ${Color.LightAsh}`,
            padding: "2.08vw 2.60vw",
            marginTop: 0
        },
        buttonContainer: {
            height: "3.02vw",
            marginBottom: "1.56vw"
        },
        itemContainer: {
            height: "27.5vw"
        }
    }
};

export const LoginFormLoader: React.FC =() => {
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    var styles  = loaderStyles.mobile;   
    if(!isMobile) {           
        let extendStyles = merge(loaderStyles.mobile, loaderStyles.desktop); 
        styles = extendStyles;
    }  

    return (
        <div style={styles.loginForm}>
            <div style={styles.buttonContainer}>
             <ContentLoader
                speed={1}
                backgroundColor={Color.LoaderBackground}
                foregroundColor={Color.LoaderForeground}
                width= "auto"
                height= {styles.buttonContainer.height}
            > 
                {isMobile ? <>
                    <rect x="22.19%" y="0" rx="3" ry="3" width="24.60%" height="100%" />
                    <rect x="46.52%" y="0" rx="3" ry="3" width="31.55%" height="100%" />
                </> : 
                <>
                    <rect x="22.13%" y="0" rx="3" ry="3" width="24.39%" height="100%" />
                    <rect x="46.34%" y="0" rx="3" ry="3" width="31.53%" height="100%" />
                </>} 
            </ContentLoader>
           </div>
            <ContentLoader
                speed={1}
                backgroundColor={Color.LoaderBackground}
                foregroundColor={Color.LoaderForeground}
                width="auto"
                height={styles.itemContainer.height}
            > 
                {isMobile ? <>
                    <rect x="4.27%" y="0" rx="3" ry="3" width="14.13%" height="4.80%" />
                    <rect x="0" y="10.02%" rx="3" ry="3" width="100%" height="10.44%" />
                    <rect x="0" y="27.97%" rx="3" ry="3" width="100%" height="10.44%" />
                    <rect x="0" y="44.5%" rx="3" ry="3" width="33.50%" height="3.76%" />
                    <rect x="38.66%" y="43.22%" rx="3" ry="3" width="33.33%" height="5.22%" />
                    <rect x="0" y="53.24%" rx="3" ry="3" width="100%" height="10.44%" />
                    <rect x="12.27%" y="66.81%" rx="3" ry="3" width="75.73%" height="3.76%" />
                    <rect x="0" y="76.5%" rx="3" ry="3" width="100%" height="0.3%" />
                    <rect x="16%" y="83.09%" rx="3" ry="3" width="68.53%" height="3.76%" />
                    <circle cx="34.07%" cy="94.5%" r="3.3%" />
                    <circle cx="49.53%" cy="94.5%" r="3.3%" />
                    <circle cx="65%" cy="94.5%" r="3.3%" />
                </> : 
                <>
                    <rect x="1.74%" y="0" rx="3" ry="3" width="25.61%" height="3.79%" />
                    <rect x="0" y="6.63%" rx="3" ry="3" width="100%" height="13.26%" />
                    <rect x="0" y="22.73%" rx="3" ry="3" width="100%" height="13.26%" />
                    <rect x="1.74%" y="41.50%" rx="3" ry="3" width="25.61%" height="3.79%" />
                    <rect x="29.79%" y="40.15%" rx="3" ry="3" width="33.97%" height="6.63%" />
                    <rect x="0" y="51.14%" rx="3" ry="3" width="100%" height="13.26%" />
                    <rect x="22.47%" y="69.13%" rx="3" ry="3" width="55.40%" height="3.79%" />
                    <rect x="0" y="77.90%" rx="3" ry="3" width="100%" height="0.3%" />
                    <rect x="27.70%" y="84.28%" rx="3" ry="3" width="44.77%" height="3.79%" />
                    <circle cx="39.06%" cy="94.50%" r="2.9%" />
                    <circle cx="49.17%" cy="94.50%" r="2.9%" />
                    <circle cx="59.27%" cy="94.50%" r="2.9%" />
                </>} 
           </ContentLoader>
        </div>
    )
}