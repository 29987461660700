import { Color } from "../enums/Color";

export const homePage = {    
    mobile: { },
    desktop: {
        height: "100vh",
        backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/sub_header.jpg)",
        backgroundSize: "100%",
        wrapper: {
            margin: "60px 9.375vw"
        },
        rowWrapper: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center"
        },
        card: {
            display: "flex",
            flexDirection: "row",
            height: "92px",
            width: "18vw",
            borderRadius: "10px",
            background: Color.White,
            cursor: "pointer",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 4.5vw 8vw 4.5vw",
            icon: {                
                width: "46px",
                height: "46px",
                marginRight: "8px",
                fill: Color.Green
            },
            name: {
                fontSize: "18px",
                fontWeight: "bold",
                marginLeft: "8px"
            }
        }
    }
}