import { Color } from "../enums/Color";

export const teachersPage = {    
    mobile: {
        viewDialog: {            
            backdrop: {
                backgroundColor: Color.Black,
                opacity: 0.15,
                position: "fixed" as "fixed",
                left: "0",
                right: "0",
                bottom: "0",
                top: "0"
            },
            content: {
                background: Color.White,
                position: "fixed",
                top: "8%",
                right: "29%",
                width: "42%",
                height: "84%",                                       
                borderRadius: "10px",
                zIndex: "4",
                boxShadow: "1px 0px 7px rgba(0,0,0,0.5)",  
                transform: "translateX(0)",
                overflowY: "scroll"
            }
        },
        deleteDialog: {
            backdrop: {
                backgroundColor: Color.Black,
                opacity: 0.15,
                position: "fixed" as "fixed",
                left: "0",
                right: "0",
                bottom: "0",
                top: "0"
            },
            content: {
                background: Color.White,
                position: "fixed",
                top: "31%",
                right: "29%",
                width: "38%",
                height: "auto",
                padding: "20px ",                        
                borderRadius: "10px",
                zIndex: "4",
                boxShadow: "1px 0px 7px rgba(0,0,0,0.5)",  
                transform: "translateX(0)"                
            }
        }
    },
    desktop: {
        margin: "16px 4% 0 4%",
        heading: {
            margin: "0 auto 0 auto",
            textAlign: "center"
        },
        buttonsWrapper: {
            display: "flex",
            flexDirection: "row",
            marginTop: "12px"
        },
        statusButton: {  
            width: "auto",
            height: "40px",          
            background: "transparent",
            fontSize: "16px",
            fontWeight: "bold",            
            marginRight: "18px",
            borderRadius: "8px",
            all: {
                color: Color.ChathamsBlue                
            },
            live: {
                color: Color.Green
            },
            pending: {
                color: Color.LemonCurry
            },
            unpaid: {
                color: Color.Red
            },
            expired: {
                color: Color.Castro
            },
            active: {
                width: "160px",
                background: Color.Green,
                color: Color.White
            }
        }, 
        newAdButton: {
            width: "180px",
            height: "40px",
            background: Color.ChathamsBlue,
            color: Color.White,
            fontSize: "16px",
            fontWeight: "bold",
            borderRadius: "8px",
            marginLeft: "auto"
        },    
        searchWrapper: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "36px"
        },
        searchSelect: {        
            wrapper: {   
                width: "260px",            
                marginLeft: "unset"          
            },
            label: {
                fontSize: "1.2vw"
            },
            hover: {
                width: "250px",
                height: "320px",
                left: "unset",
                padding: "5px"
            }
        },
        table: {
            width: "-webkit-fill-available",
            margin: "24px 0 0 0",
            border: "2px solid " + Color.Green,
            borderCollapse: "collapse",
            header: {
                height: "48px",
                backgroundColor: Color.Green,
                color: Color.White
            },
            tr: {
                height: "100px",
                border: "2px solid " + Color.Green,
                borderCollapse: "collapse",                
                textAlign: "center"
            },
            idColumn: {
                width: "60px"
            },
            imageColumn: {
                width: "100px"
            },
            nameColumn: {
                width: "220px"
            },
            subjectColumn: {
                width: "320px"
            },            
            typeColumn: {
                width: "320px"
            },
            districtColumn: {
                width: "220px"
            },
            actionColumn: {
                width: "-webkit-fill-available"
            },
            actionCell: {
                display: "flex",    
                justifyContent: "center"
            },
            iconWrapper: {
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                marginTop: "21.5px"             
            },
            image: {
                height: "80px",
                width: "80px",
                borderRadius: "8px"
            },
            action: {
                cursor: "pointer"
            },
            icon: {
                height: "12px",
                width: "12px",
                marginRight: "8px",
                view: { fill: Color.Blue },
                edit: { fill: Color.LemonCurry },
                delete: { fill: Color.Red }
            }
        },
        adView: {            
            detailContainer: {
                padding: "10px"
            },
            id: {
                fontWeight: "bold",
                margin: "0 0 16px 0"
            },
            label: {
                fontWeight: "bold",
                margin: "16px 0 0 0"
            },
            image: {
                width: "-webkit-fill-available",
                padding: "0 0 10px 10px"
            }
        },
        deleteView: {
            text: {
                marginTop: "16px",
                fontSize: "24px",
                textAlign: "center"
            },
            button: {
                height: "36px",
                width: "142px",
                background: Color.Red,
                color: Color.White,
                border: "none",
                borderRadius: "5px",
                fontWeight: "bold",
                float: "right",
                margin: "32px 0 16px 0"                
            }
        }
    }
}