import { createSearchParams } from "react-router-dom";
import { InputValidation } from "../models/InputValidation";
import { CommonTexts as Texts } from "./LayoutTexts";

export function validateEmail(email: string): boolean {
    const emailRegex = /\S+@\S+\.\S+/;    
    return emailRegex.test(email);
}

export const validatePhoneNumber = (number: string) => {
    //Valid numbers : +947********, 947********, +9407********, 9407********, 07********, 7********
    let regex = /^(?:\+94|94|\+940|940|0)?(7\d{8})$/;  
    return regex.test(number);  
}

export const getWhatsAppFormatNumber = (number: string) : string => {
    let whatsAppFormatNumber: string = "";
    let regex = /^(?:\+94|94|\+940|940|0)?(7\d{8})$/;  
    if(regex.test(number)) {
        whatsAppFormatNumber = "94" + number.substring(number.length - 9);
    }
    return whatsAppFormatNumber;
}

export const getSeoName = (value: string) => {    
    return value
    .toLowerCase()
    .replace(/[^\w\s-]/g, '') // Remove special characters
    .replace(/\s+/g, '-')     // Replace spaces with hyphens
    .trim();                  // Trim any leading/trailing spaces
}

export const getFormatedDate = (date: Date | string, excludeYear?: boolean ) => {
    if (date) {
        let formatedDate: string = "";
        if(excludeYear) {        
            formatedDate = new Date(date).toLocaleDateString("en-us", {                
                month: "long",
                day: "numeric"
            });
        }
        else {
            formatedDate = new Date(date).toLocaleDateString("en-us", {
                year: "numeric",
                month: "long",
                day: "numeric"
            });
        }
        return formatedDate;
    }
};

export const getFormatedTime = (time24: string): string => {
    const [hoursStr, minutesStr] = time24.split(':');
    let hours = parseInt(hoursStr);
    const minutes = parseInt(minutesStr);
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
  
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  
    return `${hours}:${formattedMinutes} ${ampm}`;
};

export const validatePDF = (file: File) : InputValidation => {        
    const validFileTypes = ['application/pdf'];
    let pdfValidation: InputValidation = {valid: true};        
    if (!validFileTypes.includes(file.type)) {
        pdfValidation = {valid: false, invalid: true, invalidMessage: "Invalid file format. Try pdf format"};
    } 
    else if (file.size <= 0) {
        pdfValidation = {valid: false, invalid: true, invalidMessage: "File upload was not successful. Please try again"};
    }
    return pdfValidation;
}

export const getClassWeekPaymentSlipUrl = (weekId: number, adminId: number, token: string) => {
    let params = { weekId: weekId.toString(), adminId: adminId.toString(), token: token };
    return Texts.apiBaseUrl + "admins/week-pay-slip?" + createSearchParams(params);
}