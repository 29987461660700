import axios from "axios";
import { CommonTexts as Texts} from "../helpers/LayoutTexts";
import { AdminAdSearchModel } from "../models/AdminAdSearchModel";

export async function getAdRequestModel(gradeCategoryIds?: string, districtIds?: string) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "teachers/adRequestModel", {            
            params: {
                gradeCategoryIds: gradeCategoryIds ? gradeCategoryIds : "",
                districtIds: districtIds ? districtIds : "",
                languageCode: localStorage.getItem('languageCode')!
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getGradeCategorySubjects(gradeCategoryIds?: string) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "teachers/gradeCategorySubjects", {            
            params: {
                gradeCategoryIds: gradeCategoryIds ? gradeCategoryIds : "",
                languageCode: localStorage.getItem('languageCode')!                
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getDistrictTowns(districtIds?: string) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "teachers/districtTowns", {            
            params: {
                districtIds: districtIds ? districtIds : "",
                languageCode: localStorage.getItem('languageCode')!
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function postAdResponseModel(formData: FormData) {
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "teachers/adsByAdmin", formData, {
            headers: {                
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function postAdEditResponseModel(formData: FormData) {
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "teachers/adEditByAdmin", formData, {
            headers: {                
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getAllTeachers() {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "teachers/allTeachers")
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getTeacherAdsCount() {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "teachers/adsCount")
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getTeacherAdSearchOptions() {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "teachers/adSearchOptions", {
            headers: {                
                "Language-Code": localStorage.getItem('languageCode')!
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function postTeacherAdSearch(searchModel: AdminAdSearchModel) {
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "teachers/adminAdSearch", searchModel, {
            headers: {                
                "Language-Code": localStorage.getItem('languageCode')!
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getTeacherAd(teacherAdId: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "teachers/teacherAd", {            
            params: {
                teacherAdId: teacherAdId
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getTeacherAdDetails(teacherAdId: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "teachers/adWithDetails", {
            params: {
                teacherAdId: teacherAdId,
                languageCode: localStorage.getItem('languageCode')!
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function deleteTeacherAd(teacherAdId: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .delete(Texts.apiBaseUrl + "teachers/deleteAd", {            
            params: {
                teacherAdId: teacherAdId
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}