import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StyleContext } from "../../providers/StyleContextProvider";
import { AuthContext } from "../../providers/AuthContextProvider";
import { LoggedAdmin } from "../../models/LoggedAdmin";
import { useMediaQuery } from "react-responsive";
import { Header } from "../../components/shared/Header";
import { Input } from "../../components/shared/inputs/Input";
import { NoData } from "../../components/shared/NoData";
import { Pagination } from "../../components/shared/Pagination";
import { getClassWeekPaymentSlipUrl, getFormatedDate, getFormatedTime, validatePDF } from "../../helpers/Common";
import { PopUpDialog } from "../../components/shared/dialogs/PopUpDialog";
import { Button } from "../../components/shared/Button";
import { useAlert } from "../../hooks/useAlert";
import { OnlineClassWeek } from "../../models/OnlineClassWeek";
import { OnlineClassWeekSearchCriteria } from "../../models/OnlineClassWeekSearchCriteria";
import { getClassWeeks, saveClassWeekChanges } from "../../services/OnlineClassService";
import { Switch } from "../../components/shared/inputs/Switch";
import PDFViewer from "../../components/shared/PDFViewer";
import Dropzone from "react-dropzone";
import { ReactComponent as FileUpload } from "../../assets/svgs/file-upload.svg";
import { ReactComponent as CloseCircle } from "../../assets/svgs/close-circle-ash.svg";

export const OnlineClassWeeksPage: React.FC = () => {
    const [searchParams] = useSearchParams(); 
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const authContext = useContext(AuthContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("onlineClassWeeksPage"));  
    const admin: LoggedAdmin | null = authContext.getLoggedAdmin();  
    const classId: number | undefined = searchParams.get("class-id") === null ? undefined : +searchParams.get("class-id")!;
    const page: number = searchParams.get("page") === null ? 1 : +searchParams.get("page")!;
    const [totalPages, setTotalPages] = useState<number>();       
    const pageSize: number = 50;
    const [searchTeacherName, setSearchTeacherName] = useState<string>("");
    const [teacherName, setTeacherName] = useState<string>();        
    const [searchWhatsappNo, setSearchWhatsappNo] = useState<string>("");
    const [whatsappNo, setWhatsappNo] = useState<string>();    
    const [searchTitle, setSearchTitle] = useState<string>("");
    const [title, setTitle] = useState<string>();
    const [date, setDate] = useState<string>();
    const [classWeeks, setClassWeeks] = useState<OnlineClassWeek[]>([]);
    const [selectedClassWeek, setSelectedClassWeek] = useState<OnlineClassWeek>();
    const [slipFile, setSlipFile] = useState<File>();
    const [slipFileUrl, setSlipFileUrl] = useState<string>();
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" }); 
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();   
    const [hoveredIndex, setHoveredIndex] = useState<number>(-1);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [openFileDialog, setOpenFileDialog] = useState<boolean>(false);
    const {Alert, openAlert} = useAlert();
    const [loading, setLoading] = useState<boolean>(true); 

    useEffect(() => {  
        if(admin === null) {            
            navigate('/admin/login');
        }                                   
    }, []);

    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("onlineClassWeeksPage")); 
    }, [isMobile]);

    useEffect(() => {
        getPaymentList();                                    
    }, [page, teacherName, whatsappNo, title, date]);

    async function getPaymentList() {        
        let searchModel: OnlineClassWeekSearchCriteria = {
            SystemAdminId: admin!.AdminId,
            PageNo: page,
            RecordsPerPage: pageSize,    
            OnlineClassId: classId,            
            TeacherName: teacherName,            
            WhatsappNo: whatsappNo,	 
            Title: title,       
	        Date: date ? date.split('T')[0] : undefined
        }
        await getClassWeeks(searchModel, admin!.Token)
        .then((weekList: OnlineClassWeek[]) => {
            setClassWeeks(weekList);            
            if(weekList.length > 0) { 
                let totalPageCount = Math.ceil(weekList[0].RecordCount / pageSize);            
                setTotalPages(totalPageCount);                
            }  
            else {
                setTotalPages(undefined);
            }  
            setLoading(false);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });                       
    }

    const handlePagination = (pageNumber: number) => {
        searchParams.set("page", pageNumber.toString());                     
        const newUrl = `?${searchParams}`;
        navigate(newUrl);        
    }

    const handleTeacherNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();  
        const value = event.target.value;
        setSearchTeacherName(value);

        // Clear previous timeout
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Set new timeout
        const newTimeoutId = setTimeout(() => {
            setTeacherName(value.trim());
        }, 1000);
        setTimeoutId(newTimeoutId);
    };

    const handleWhatsappNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();  
        const value = event.target.value;
        setSearchWhatsappNo(value);

        // Clear previous timeout
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Set new timeout
        const newTimeoutId = setTimeout(() => {
            setWhatsappNo(value.trim());
        }, 1000);
        setTimeoutId(newTimeoutId);
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();  
        const value = event.target.value;
        setSearchTitle(value);

        // Clear previous timeout
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Set new timeout
        const newTimeoutId = setTimeout(() => {
            setTitle(value.trim());
        }, 1000);
        setTimeoutId(newTimeoutId);
    };

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();  
        const value = event.target.value;
        setDate(value);
    };

    const selectClassWeek = (classWeek: OnlineClassWeek) => {
        setSelectedClassWeek(classWeek);
        if(classWeek.IsRevenueTransferred) {
            const slipURL: string = getClassWeekPaymentSlipUrl(classWeek.OnlineClassWeekId, admin!.AdminId, admin!.Token);
            setSlipFileUrl(slipURL);                
        }
        setOpenDialog(true);
    };

    const handleIsCompletedChange = () => {        
        setSelectedClassWeek((values: any) => ({ ...values, ["IsCompleted"]: !selectedClassWeek!.IsCompleted }));        
    };

    const handleIsRevenueTransferredChange = () => {        
        setSelectedClassWeek((values: any) => ({ ...values, ["IsRevenueTransferred"]: !selectedClassWeek!.IsRevenueTransferred }));         
    };

    const handleSlipFileChange = (uploadedFiles: File[]) => { 
        let fileUrl: string;
        if(uploadedFiles.length > 0) {
            const uploadedSlip = uploadedFiles[0];
            const slipValidation = validatePDF(uploadedSlip);
            if(slipValidation.valid) {
                setSlipFile(uploadedSlip);
                fileUrl = URL.createObjectURL(uploadedSlip);
                setSlipFileUrl(fileUrl);                                                                                                   
            }
            else{
                openAlert("error", slipValidation.invalidMessage!);
            }                                           
        }
        // Revoke the object URL on component unmount
        return () => URL.revokeObjectURL(fileUrl);
    }
    
    const removeSelectedSlipFile = () => {        
        setSlipFile(undefined);
        setSlipFileUrl(undefined);        
    }

    async function handleSubmitChanges() {        
        const formData = new FormData();
        formData.append('isCompleted', selectedClassWeek!.IsCompleted.toString());
        formData.append('isRevenueTransferred', selectedClassWeek!.IsRevenueTransferred.toString());
        formData.append('slipFile', slipFile!);
        closeDialog();
        await saveClassWeekChanges(formData, selectedClassWeek!.OnlineClassWeekId, admin!.AdminId, admin!.Token)
        .then((success: boolean) => {              
            if(success) {
                getPaymentList();
                openAlert("success", "Class week changes has been submitted successfully.");                                                                    
            }
            else {
                openAlert("error", "Oops! something went wrong.");
            }
        })
        .catch((error) => {            
            //toErrorPage(error);
        }); 
    }

    const closeDialog = () => {
        setSelectedClassWeek(undefined);
        setSlipFile(undefined);
        setSlipFileUrl(undefined);
        setOpenDialog(false);
    }

    return (   
        <>
            <div style={styles}>
                <Header />          
                <h1 style={styles.heading}>{"Online Classes - Weeks"}</h1>      
                <div style={styles.searchWrapper}>
                    <div style={styles.inputWrapper}>
                        <label style={styles.label}>
                            {"Teacher Name"}
                        </label>
                        <Input
                            type={"text"}
                            value={searchTeacherName}
                            styles={styles.input}
                            onChange={handleTeacherNameChange}                                                                                    
                        />
                    </div>
                    <div style={styles.inputWrapper}>
                        <label style={styles.label}>
                            {"Whatsapp Number"}
                        </label>
                        <Input
                            type={"text"}
                            value={searchWhatsappNo}
                            styles={styles.input}
                            onChange={handleWhatsappNoChange}                                                                                    
                        />
                    </div>
                    <div style={styles.inputWrapper}>
                        <label style={styles.label}>
                            {"Week Title"}
                        </label>
                        <Input
                            type={"text"}
                            value={searchTitle}
                            styles={styles.input}
                            onChange={handleTitleChange}                                                                                    
                        />
                    </div>                    
                    <div style={styles.inputWrapper}>
                        <label style={styles.label}>
                            {"Date"}
                        </label>
                        <Input
                            type={"date"}
                            value={date}
                            styles={styles.input}
                            onChange={handleDateChange}                                                                                    
                        />
                    </div>
                </div>
                <div>                
                    {(classWeeks.length === 0) ? <NoData/> :
                    <table style={styles.table}>
                        <thead>
                            <tr style={styles.table.header}> 
                                <th style={styles.table.idColumn}>{"Id"}</th>                           
                                <th style={styles.table.teacherColumn}>{"Teacher"}</th>
                                <th style={styles.table.weekColumn}>{"Week"}</th> 
                                <th style={styles.table.paymentColumn}>{"Payment"}</th>
                                <th style={styles.table.studentColumn}>{"Students"}</th>
                                <th style={styles.table.revenueColumn}>{"Revenue"}</th>
                                <th style={styles.table.transferColumn}>{"Transferred"}</th>                                
                            </tr>
                        </thead>
                        <tbody>
                            {classWeeks.map((classWeek, index) => (
                                <tr 
                                    key={index} 
                                    style={{...styles.table.tr, 
                                        ...(index === hoveredIndex && styles.table.hoveredTr), 
                                        ...(((classWeek.IsExpired || classWeek.IsCompleted) && classWeek.IsRevenueTransferred) && styles.table.expiredTr),
                                        ...(((classWeek.IsExpired || classWeek.IsCompleted) && !classWeek.IsRevenueTransferred) && styles.table.transferTr)}}
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(-1)}
                                    onClick={() => selectClassWeek(classWeek)}
                                >
                                    <td>{classWeek.OnlineClassWeekId}</td>
                                    <td>
                                        {classWeek.TeacherName}<br/>
                                        {classWeek.WhatsappNo}
                                    </td>
                                    <td>
                                        {classWeek.Title}<br/>
                                        {getFormatedDate(classWeek.Date)} {getFormatedTime(classWeek.Time)}
                                    </td>
                                    <td>
                                        {classWeek.IsMonthlyPayment ? "Monthly Payment" : "Weekly Payment"}<br/>
                                        {classWeek.ClassFee + " LKR"}                                        
                                    </td>
                                    <td>
                                        {classWeek.PaidStudentCount + " Paid Students"}<br/>
                                        {classWeek.AttendStudentCount + " Attend Students"}
                                    </td>
                                    <td>
                                        {"Gross Revenue: " + classWeek.WeekGrossRevenue}<br/>
                                        {"Net Revenue: " + (classWeek.WeekGrossRevenue - (classWeek.WeekGrossRevenue * 0.04)) + " LKR"}
                                    </td>
                                    <td>{classWeek.IsRevenueTransferred ? "Revenue Transferred" : "Not Transferred"}</td>                                    
                                </tr>
                            ))}
                        </tbody>
                    </table>}
                    {(totalPages! > 1) && <Pagination page={page} totalPages={totalPages!} handlePagination={handlePagination}/>}
                </div>
            </div>                 
            <PopUpDialog styles={styles.dialog} open={openDialog} toggle={closeDialog}>
                {selectedClassWeek && <>
                    <div style={styles.dialog.rowWrapper}>
                        <div style={styles.dialog.idColumn}>{selectedClassWeek.OnlineClassWeekId}</div>
                        <div style={styles.dialog.teacherColumn}>
                            {selectedClassWeek.TeacherName}<br/>
                            {selectedClassWeek.WhatsappNo}
                        </div>
                        <div style={styles.dialog.weekColumn}>
                            {selectedClassWeek.Title}<br/>
                            {getFormatedDate(selectedClassWeek.Date)} {getFormatedTime(selectedClassWeek.Time)}
                        </div>
                        <div style={styles.dialog.paymentColumn}>
                            {selectedClassWeek.IsMonthlyPayment ? "Monthly Payment" : "Weekly Payment"}<br/>
                            {selectedClassWeek.ClassFee + " LKR"}                                        
                        </div>
                        <div style={styles.dialog.studentColumn}>
                            {selectedClassWeek.PaidStudentCount + " Paid Students"}<br/>
                            {selectedClassWeek.AttendStudentCount + " Attend Students"}
                        </div>
                        <div style={styles.dialog.revenueColumn}>
                            {"Gross Revenue: " + selectedClassWeek.WeekGrossRevenue}<br/>
                            {"Net Revenue: " + (selectedClassWeek.WeekGrossRevenue - (selectedClassWeek.WeekGrossRevenue * 0.04)) + " LKR"}
                        </div>
                        <div style={styles.dialog.transferColumn}>{selectedClassWeek.IsRevenueTransferred ? "Revenue Transferred" : "Not Transferred"}</div> 
                        <CloseCircle style={styles.dialog.dialogClose} onClick={closeDialog} />                       
                    </div>
                    <div style={styles.dialog.actionWrapper}>
                        <div>
                            <div style={styles.dialog.switchWrapper} onClick={handleIsCompletedChange}>
                                <span style={styles.dialog.switchLabel}>{"Make class as completed:"}</span>
                                <Switch checked={selectedClassWeek.IsCompleted}/>                
                            </div>
                            <div style={styles.dialog.switchWrapper} onClick={handleIsRevenueTransferredChange}>
                                <span style={styles.dialog.switchLabel}>{"Make class as revenue transferred:"}</span>
                                <Switch checked={selectedClassWeek.IsRevenueTransferred}/>                
                            </div>
                        </div> 
                        <div>
                            <div style={styles.dialog.fileLabel}>
                                {"Upload revenue transfer slip (Supported Files: pdf)"}
                            </div>
                            <div style={styles.dialog.rowWrapper}>
                                {slipFileUrl && <>
                                    <PDFViewer pdfUrl={slipFileUrl} styles={styles.dialog.pdfViewer} handleClick={() => setOpenFileDialog(!openFileDialog)}/>
                                    <CloseCircle style={styles.dialog.fileClose} onClick={removeSelectedSlipFile} />
                                </>}
                                <div style={{...styles.dialog.fullWrapper, ...(slipFileUrl && styles.dialog.halfWrapper)}}>
                                    <Dropzone onDrop={acceptedFiles => handleSlipFileChange(acceptedFiles)} multiple={false}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps({ style: styles.dialog.uploadFile })} className="center-content">
                                                <input {...getInputProps()} /> 
                                                <div style={styles.dialog.uploadIconWrapper}>
                                                    <FileUpload style={styles.dialog.uploadIcon} />
                                                </div>
                                                <div style={styles.dialog.uploadText}>
                                                    {"Add the slip file if available."}                                  
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <Button style={styles.dialog.submitButton} onClick={handleSubmitChanges}>{"Submit Changes"}</Button>                  
                </>}
            </PopUpDialog>
            <PopUpDialog styles={styles.fileDialog} open={openFileDialog} toggle={() => setOpenFileDialog(!openFileDialog)}>
                {slipFileUrl && <>
                    <PDFViewer pdfUrl={slipFileUrl} styles={styles.fileDialog.pdfViewer} />
                    <CloseCircle style={styles.fileDialog.fileClose} onClick={() => setOpenFileDialog(!openFileDialog)} />
                </>}
            </PopUpDialog>
            <Alert />
        </>         
    )
}