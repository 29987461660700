import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StyleContext } from "../../providers/StyleContextProvider";
import { AuthContext } from "../../providers/AuthContextProvider";
import { LoggedAdmin } from "../../models/LoggedAdmin";
import { useMediaQuery } from "react-responsive";
import { HeaderTexts as Texts } from '../../helpers/LayoutTexts';
import { DropdownItem } from "./DropdownItem";
import { Button } from "./Button";
import { Dropdown } from "./Dropdown";
import { postAdminLogout } from "../../services/AccountService";

export const ProfileEntrance: React.FC = () => {  
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const authContext = useContext(AuthContext);
    const admin: LoggedAdmin | null = authContext.getLoggedAdmin();    
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("profileEntrance"));                     
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });    
    const [userAvatar, setUserAvatar] = useState<string>(Texts.defaultAvatar);

    useEffect(() => {                 
        let style = styleContext.getComponentStyle("profileEntrance");         
        setStyles(style);                    
    }, [isMobile]);   

    const profileItems: any[] = [        
        { item: 
            <div onClick={() => handleToProfile()} style={styles.dropdownLabel}>
                <DropdownItem styles={styles.dropdownItem}>{"Profile"}</DropdownItem>
            </div>
        },
        { item: 
            <div onClick={() => handleLogout()} style={styles.dropdownLabel}>
                <DropdownItem styles={styles.dropdownItem}>{"Logout"}</DropdownItem>
            </div>
        }   
    ]

    const handleToProfile = () => {        
        navigate('/admin-profile');        
    }    

    const handleAdminLogin = () => {               
        navigate('/admin/login');
    }

    const handleLogout = () => {
        localStorage.removeItem("token");        
        postAdminLogout(admin!.AdminId, admin!.Token);
        localStorage.removeItem("admin");
        navigate('/admin/login');        
    }

    return (
        <div style={styles.wrapper}>                        
            {admin ? <div style={styles.userWrapper}>
                <Dropdown styles={styles.userDropdown} items={profileItems}>
                    <img src={userAvatar} style={styles.userAvatar} />
                </Dropdown>
            </div> : 
            <div style={styles.buttonWrapper}>
                <Button type="button" style={styles.loginButton} onClick={handleAdminLogin}>{"Admin Login"}</Button>                
            </div>}
        </div>
    );
}