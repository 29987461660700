import axios from "axios";
import { CommonTexts as Texts} from "../helpers/LayoutTexts";
import { PaymentSearchCriteria } from "../models/PaymentSearchCriteria";

export async function getPayments(searchModel: PaymentSearchCriteria, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "admins/search-payments", {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            params: searchModel
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function changePaymentStatus(paymentId: number, adminId: number, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "admins/change-payment-status?paymentId=" + paymentId + "&adminId=" + adminId, {}, {
            headers: {
                'Authorization' : `Bearer ${token}`
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}