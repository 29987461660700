import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const onlineClassWeeksPage = {    
    mobile: {
        label: {
            fontSize: "3.86vw",
            fontFamily: Font.Poppins,
            color: Color.DeepAsh,
            margin: "0 0 1.21vw 3.62vw"
        },
        input: {
            wrapper: {
                margin: "auto",
                alignItems: "center"
            },               
            input: { 
                height: "11.08vw",
                padding: "0 3.62vw",                    
                fontSize: "3.86vw",
                border: "1px solid " + Color.PhilippineGray,
                borderRadius: "1.21vw",
                borderBottom: "1px solid " + Color.PhilippineGray
            }
        },
        dialog: {
            pdfViewer: {
                width: "49%",  
                height: "51vw",
                border: "1px solid " + Color.PhilippineGray,
                borderRadius: "1.21vw",
                overflow: "hidden",
                cursor: "pointer"
            },
            fileClose: {
                fill: Color.Black,
                margin: "1.45vw 0 0 -10.14vw",
                cursor: "pointer"
            },
            fullWrapper: {
                width: "-webkit-fill-available"
            },
            halfWrapper: {
                width: "49%"
            },
            uploadFile: {
                display: "flex",
                flexDirection: "column",
                height: "51vw",
                border: "1px dashed " + Color.PhilippineGray,
                borderRadius: "1.21vw",
                cursor: "pointer",                
                WebkitTapHighlightColor: "transparent",
                justifyContent: "center",
                alignItems: "center"
            },
            uploadIconWrapper: {                
                width: "9.66vw",
                height: "9.66vw",
                borderRadius: "50%",
                backgroundColor: Color.LightAsh,
                marginBottom: "2.41vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            },
            uploadIcon: {
                width: "3.14vw",
                height: "auto"
            },
            uploadText: {
                fontSize: "3.86vw",
                fontFamily: Font.Poppins,
                color: Color.DeepAsh
            }
        },
        fileDialog: {
            pdfViewer: {
                border: "1px solid " + Color.PhilippineGray,
                borderRadius: "1.21vw",
                overflow: "hidden"
            },
            fileClose: {
                position: "fixed",
                top: "0.31vw",
                right: "0.31vw",
                cursor: "pointer"
            }            
        }
    },
    desktop: {
        margin: "16px 4% 0 4%",  
        heading: {
            margin: "0 auto 2.18vw auto",
            textAlign: "center"
        },          
        searchWrapper: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "2.125vw"
        },
        label: {
            fontSize: "0.94vw",
            margin: "0 0 0.52vw 0.52vw"
        },        
        input: {              
            input: { 
                height: "2.4vw",
                padding: "0 1.56vw",
                fontSize: "0.83vw",
                borderRadius: "0.52vw"
            }
        },
        table: {
            width: "-webkit-fill-available",
            margin: "24px 0 0 0",
            border: "2px solid " + Color.Green,
            borderCollapse: "collapse",
            header: {
                height: "48px",
                backgroundColor: Color.Green,
                color: Color.White
            },
            tr: {
                height: "100px",
                border: "2px solid " + Color.Green,
                borderCollapse: "collapse",                
                textAlign: "center"
            },
            hoveredTr: {
                backgroundColor: Color.LightAsh,
                cursor: "pointer"
            },
            expiredTr: {
                backgroundColor: Color.Ash
            },
            transferTr: {
                backgroundColor: Color.Linen
            },
            idColumn: {
                width: "6%"
            },
            teacherColumn: {
                width: "16%"
            },
            weekColumn: {
                width: "22%"
            },
            paymentColumn: {
                width: "14%"
            },            
            studentColumn: {
                width: "14%"
            },
            revenueColumn: {
                width: "14%"
            },
            transferColumn: {
                width: "12%"
            }
        },
        dialog: {            
            backdrop: {
                backgroundColor: Color.Black,
                opacity: 0.15,
                position: "fixed" as "fixed",
                left: "0",
                right: "0",
                bottom: "0",
                top: "0"
            },
            content: {
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",                                
                width: "80%",                                                                                       
                borderRadius: "0.52vw",
                boxShadow: "1px 0px 7px rgba(0,0,0,0.5)", 
                background: Color.White,                 
                padding: "1.04vw"
            },
            rowWrapper: {
                display: "flex",
                flexDirection: "row",
                textAlign: "center"
            },
            idColumn: {
                width: "6%"
            },
            teacherColumn: {
                width: "16%"
            },
            weekColumn: {
                width: "22%"
            },
            paymentColumn: {
                width: "14%"
            },            
            studentColumn: {
                width: "14%"
            },
            revenueColumn: {
                width: "14%"
            },
            transferColumn: {
                width: "12%"
            },
            dialogClose: {
                position: "fixed",
                top: "0.52vw",
                right: "0.52vw",
                cursor: "pointer"
            },
            actionWrapper: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                margin: "2.5vw 8% 1.45vw 8%"
            },
            switchWrapper: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "1.18vw"
            },
            switchLabel: {
                marginRight: "0.83vw"
            },
            fileLabel: {
                marginBottom: "0.52vw"
            },
            pdfViewer: { 
                height: "17.45vw",                
                borderRadius: "0.52vw"
            },
            fileClose: {
                margin: "0.35vw 1.14vw 0 -1.44vw"
            },
            uploadFile: {
                height: "17.45vw",
                borderRadius: "0.52vw"
            },
            uploadIconWrapper: {                
                width: "2.60vw",
                height: "2.60vw",
                marginBottom: "0.78vw"
            },
            uploadIcon: {
                width: "0.84vw"
            },
            uploadText: {
                fontSize: "0.94vw"
            },
            submitButton: {
                display: "block",
                backgroundColor: "transparent",
                border: "3px solid " + Color.Green,
                padding: "0.625vw 3.83vw",
                borderRadius: "0.52vw",
                margin: "1.25vw 0 0 auto",
                fontWeight: "bold",
                fontSize: "0.93vw"
            }
        },
        fileDialog: {            
            backdrop: {
                backgroundColor: Color.Black,
                opacity: 0.15,
                position: "fixed" as "fixed",
                left: "0",
                right: "0",
                bottom: "0",
                top: "0"
            },
            content: {
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: Color.White,                
                width: "30.99vw",
                height: "43.85vw",                
                borderRadius: "0.52vw",
                boxShadow: "1px 0px 7px rgba(0,0,0,0.5)",                
                padding: "1.04vw"
            },            
            pdfViewer: {               
                borderRadius: "0.52vw"
            },
            fileClose: {
                top: "0.31vw",
                right: "0.31vw"
            }
        }
    }
}