import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const paymentsPage = {    
    mobile: {
        label: {
            fontSize: "3.86vw",
            fontFamily: Font.Poppins,
            color: Color.DeepAsh,
            margin: "0 0 1.21vw 3.62vw"
        },
        input: {
            wrapper: {
                margin: "auto",
                alignItems: "center"
            },               
            input: { 
                height: "11.08vw",
                padding: "0 3.62vw",                    
                fontSize: "3.86vw",
                border: "1px solid " + Color.PhilippineGray,
                borderRadius: "1.21vw",
                borderBottom: "1px solid " + Color.PhilippineGray
            }
        }
    },
    desktop: {
        margin: "16px 4% 0 4%", 
        heading: {
            margin: "0 auto 2.18vw auto",
            textAlign: "center"
        },           
        searchWrapper: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "2.125vw"
        },
        label: {
            fontSize: "0.94vw",
            margin: "0 0 0.52vw 0.52vw"
        },        
        input: {              
            input: { 
                height: "2.4vw",
                padding: "0 1.56vw",
                fontSize: "0.83vw",
                borderRadius: "0.52vw"
            }
        },
        table: {
            width: "-webkit-fill-available",
            margin: "24px 0 0 0",
            border: "2px solid " + Color.Green,
            borderCollapse: "collapse",
            header: {
                height: "48px",
                backgroundColor: Color.Green,
                color: Color.White
            },
            tr: {
                height: "100px",
                border: "2px solid " + Color.Green,
                borderCollapse: "collapse",                
                textAlign: "center"
            },
            hoveredTr: {
                backgroundColor: Color.LightAsh,
                cursor: "pointer"
            },
            failTr: {
                backgroundColor: Color.Linen
            },
            idColumn: {
                width: "4.17vw"
            },
            sourceColumn: {
                width: "11.04vw"
            },
            typeColumn: {
                width: "9.89vw"
            },
            userColumn: {
                width: "20.83vw"
            },            
            amountColumn: {
                width: "7.29vw"
            },
            statusColumn: {
                width: "11.45vw"
            },
            refferenceColumn: {
                width: "11.98vw"
            },
            dateColumn: {
                width: "10.94vw"
            }
        },
        dialog: {            
            backdrop: {
                backgroundColor: Color.Black,
                opacity: 0.15,
                position: "fixed" as "fixed",
                left: "0",
                right: "0",
                bottom: "0",
                top: "0"
            },
            content: {
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",                                
                width: "80%",
                borderRadius: "0.52vw",
                boxShadow: "1px 0px 7px rgba(0,0,0,0.5)",  
                background: Color.White,
                padding: "1.04vw"
            },
            body: {
                display: "flex",
                flexDirection: "row",
                textAlign: "center"
            },
            idColumn: {
                width: "6%"
            },
            sourceColumn: {
                width: "12%"
            },
            typeColumn: {
                width: "12%"
            },
            userColumn: {
                width: "25%"
            },            
            amountColumn: {
                width: "8%"
            },
            statusColumn: {
                width: "12%"
            },
            refferenceColumn: {
                width: "12%"
            },
            dateColumn: {
                width: "13%"
            },
            dialogClose: {
                position: "fixed",
                top: "0.31vw",
                right: "0.31vw",
                cursor: "pointer"
            },
            submitButton: {
                display: "block",
                backgroundColor: "transparent",
                border: "3px solid " + Color.Green,
                padding: "0.625vw 0.83vw",
                borderRadius: "0.52vw",
                margin: "1.25vw 0 0 auto",
                fontWeight: "bold",
                fontSize: "0.93vw"
            }
        }
    }
}