import { useContext, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { InputLoader } from "../../components/loaders/InputLoader";
import { SearchContentLoader } from "../../components/loaders/SearchContentLoader";
import { Option } from "../../models/Option";
import { MenuOption } from "../../models/MenuOption";
import { StyleContext } from "../../providers/StyleContextProvider";
import { getAdRequestModel, getDistrictTowns, getGradeCategorySubjects, getTeacherAd, postAdEditResponseModel } from "../../services/TeacherService";
import { Button } from "../../components/shared/Button";
import { TeacherAdRequestModel } from "../../models/TeacherAdRequestModel";
import { MultySelectMultyMenuInput } from "../../components/shared/inputs/MultySelectMultyMenuInput";
import { MultySelectInput } from "../../components/shared/inputs/MultySelectInput";
import { TeacherAdResponseModel } from "../../models/TeacherAdResponseModel";
import { TeacherAd } from "../../models/TeacherAd";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "../../components/shared/inputs/Input";
import { InputValidation } from "../../models/InputValidation";
import { getWhatsAppFormatNumber, validatePhoneNumber } from "../../helpers/Common";
import { ResponseModel } from "../../models/ResponseModel";
import { Switch } from "../../components/shared/inputs/Switch";
import { PopUpDialog } from "../../components/shared/dialogs/PopUpDialog";

export const EditAdsPage: React.FC = () => {
    let params = useParams(); 
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);    
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("newAds"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });    
    const [loading, setLoading] = useState<boolean>(true);
    const [subjectLoading, setSubjectLoading] = useState<boolean>(false); 
    const [townLoading, setTownLoading] = useState<boolean>(false);   
    const [teacherName, setTeacherName] = useState<string>("");
    const [nameValidation, setNameValidation] = useState<InputValidation>({});
    const [whatsappNo, setWhatsappNo] = useState<string>("");
    const [whatsappValidation, setWhatsappValidation] = useState<InputValidation>({});    
    const [categoryGrades, setCategoryGrades] = useState<MenuOption[]>([]);  
    const [selectedGradeCategoryIds, setSelectedGradeCategoryIds] = useState<string>();   
    const [selectedGradeIds, setSelectedGradeIds] = useState<string>();
    const [subjects, setSubjects] = useState<Option[]>([]);    
    const [selectedSubjectIds, setSelectedSubjectIds] = useState<string>();
    const [subjectValidation, setSubjectValidation] = useState<InputValidation>({});
    const [mediums, setMediums] = useState<Option[]>([]);    
    const [selectedMediumIds, setSelectedMediumIds] = useState<string>();
    const [classTypes, setClassTypes] = useState<Option[]>([]);    
    const [selectedClassTypeIds, setSelectedClassTypeIds] = useState<string>();
    const [districts, setDistricts] = useState<Option[]>([]);   
    const [selectedDistrictIds, setSelectedDistrictIds] = useState<string>();
    const [districtTowns, setDistrictTowns] = useState<MenuOption[]>([]);
    const [selectedTownIds, setSelectedTownIds] = useState<string>();  
    const fileInputRef = useRef<HTMLInputElement>(null); 
    const [selectedImage, setSelectedImage] = useState<File | undefined>(undefined);
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string>();  
    const [fileValidation, setFileValidation] = useState<InputValidation>({});  
    const [isLivePublish, setIsLivePublish] = useState<boolean>(false);
    const [isFeatured, setIsFeatured] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    useEffect(() => {    
        window.scrollTo(0, 0);        
        getTeacherAdData();                                          
    }, []);

    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("newAds"));          
    }, [isMobile]);
      
    async function getTeacherAdData() {
        await getTeacherAd(+params.adId!)
        .then((teacherAd: TeacherAd) => {   
            setTeacherName(teacherAd.TeacherName);
            setWhatsappNo(teacherAd.WhatsappNo);  
            setSelectedGradeCategoryIds(teacherAd.GradeCategoryIds);       
            setSelectedGradeIds(teacherAd.GradeIds);
            setSelectedSubjectIds(teacherAd.SubjectIds);
            setSelectedMediumIds(teacherAd.MediumIds);
            setSelectedClassTypeIds(teacherAd.ClassTypeIds);
            setSelectedDistrictIds(teacherAd.DistrictIds);
            setSelectedTownIds(teacherAd.TownIds);
            setImagePreviewUrl(teacherAd.TeacherAdUrl);
            setIsFeatured(teacherAd.IsFeatured);
            getRequestModelData(teacherAd.GradeCategoryIds, teacherAd.DistrictIds);
        })
        .catch((error) => {
            // toErrorPage(error);
        });         
    }

    async function getRequestModelData(gradeCategoryIds?: string, districtIds?: string) {
        await getAdRequestModel(gradeCategoryIds, districtIds)
        .then((requestModel: TeacherAdRequestModel) => {            
            setCategoryGrades(requestModel.CategoryGrades);
            setSubjects(requestModel.Subjects);
            setMediums(requestModel.Mediums);
            setClassTypes(requestModel.ClassTypes);
            setDistricts(requestModel.Districts);
            setDistrictTowns(requestModel.DistrictTowns);
            setLoading(false);        
        })
        .catch((error) => {
            // toErrorPage(error);
        });         
    }
    
    async function getGradeCategorySubjectList(gradeCategoryIds?: string) {                
        await getGradeCategorySubjects(gradeCategoryIds)
        .then((gradeCategorySubjectList: Option[]) => {            
            setSubjects(gradeCategorySubjectList);
            setSubjectLoading(false);            
        })
        .catch((error) => {
            // toErrorPage(error);
        });                    
    }

    async function getDistrictTownList(districtIds?: string) {                
        await getDistrictTowns(districtIds)
        .then((districtTownList: MenuOption[]) => {            
            setDistrictTowns(districtTownList);
            setTownLoading(false);           
        })
        .catch((error) => {
            // toErrorPage(error);
        });                            
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        if(name === "teacherName") {
            setTeacherName(value);
            if(nameValidation.invalid) {
                validateInput(name, value);
            }
        }
        else if(name === "whatsappNo") {
            setWhatsappNo(value);
            if(whatsappValidation.invalid) {
                validateInput(name, value);
            }
        }        
    }

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        validateInput(name, value);        
    }
    
    const validateInput = (name: string, value: string) => {        
        if(name === "teacherName") {
            if(value === "") {
                setNameValidation({valid: false, invalid: true, invalidMessage: "Teacher name is required"});
            } 
            else {
                setNameValidation({valid: true, invalid: false});
            }
        }
        if(name === "whatsappNo") {            
            if(value === "") {
                setWhatsappValidation({valid: false, invalid: true, invalidMessage: "Whatsapp number is required"});
            } 
            else if (!validatePhoneNumber(value)) {
                setWhatsappValidation({valid: false, invalid: true, invalidMessage: "Invalid Whatsapp number"});
            } 
            else {
                setWhatsappValidation({valid: true, invalid: false});
            }
        }         
    } 
    
    const validateForm = (): boolean => {
        let valid: boolean = true;        
        if(teacherName === "") {
            setNameValidation({valid: false, invalid: true, invalidMessage: "Teacher name is required"});
            valid = false;
        }        
        if(whatsappNo === "") {
            setWhatsappValidation({valid: false, invalid: true, invalidMessage: "Whatsapp number is required"});
            valid = false;
        } 
        else if(!validatePhoneNumber(whatsappNo)) {
            setWhatsappValidation({valid: false, invalid: true, invalidMessage: "Invalid Whatsapp number"});
            valid = false;
        }
        if(!selectedSubjectIds) {                                
            setSubjectValidation({valid: false, invalid: true, invalidMessage: "Subject is required"});
            valid = false;
        }
        if(!imagePreviewUrl) {
            setFileValidation({valid: false, invalid: true, invalidMessage: "Advertisement is required"});
            valid = false;
        }
        return valid;
    }
    
    const getSelectedGradeCategories = (gradeCategoryIds?: string) => {
        setSubjectLoading(true);
        setSelectedGradeCategoryIds(gradeCategoryIds);
        setSelectedSubjectIds(undefined);                               
        getGradeCategorySubjectList(gradeCategoryIds);        
    }

    const getSelectedDistricts = (districtIds?: string) => {
        setTownLoading(true);
        setSelectedDistrictIds(districtIds);
        setSelectedTownIds(undefined);            
        getDistrictTownList(districtIds);        
    }

    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            if(file.size > 0 && file.size <= 2097152) {
                setSelectedImage(file);
                setImagePreviewUrl(URL.createObjectURL(file));
                setFileValidation({valid: true, invalid: false});
            }
            else {
                setFileValidation({valid: false, invalid: true, invalidMessage: "File size is invalid"});
            }
        }
    };

    async function postResponse () {    
        var isValid = validateForm();
        if(isValid) {    
            setLoading(true); 
            let finalDistrictIds = selectedDistrictIds; 
            if(selectedClassTypeIds && selectedClassTypeIds.includes("4")) {                 
                if(!selectedDistrictIds) {
                    finalDistrictIds = "0";
                }
                else {
                    finalDistrictIds = "0," + selectedDistrictIds;
                }
            }   
            const responseModel: TeacherAdResponseModel = {
                TeacherAdId: +params.adId!,
                TeacherName: teacherName,
                WhatsappNo: getWhatsAppFormatNumber(whatsappNo),
                GradeCategoryIds: selectedGradeCategoryIds,
                GradeIds: selectedGradeIds,
                SubjectIds: selectedSubjectIds,
                MediumIds: selectedMediumIds,
                ClassTypeIds: selectedClassTypeIds,
                DistrictIds: finalDistrictIds,
                TownIds: selectedTownIds,
                IsFeatured: isFeatured,
                IsLivePublish: isLivePublish               
            }
            const responseString = JSON.stringify(responseModel);
            const formData = new FormData();
            formData.append('response', responseString);  
            formData.append('adImage', selectedImage!);
            await postAdEditResponseModel(formData)
            .then((response: ResponseModel) => {                
                setOpenDialog(true);
            })
            .catch((error) => {            
                //toErrorPage(error);
            });
        }                       
    }

    const closeDialog = () => {
        setOpenDialog(false);                
        navigate('/teachers');
    }

    return (
        <div style={styles}>                 
            {loading ? <SearchContentLoader/> : 
            <>  
                <div style={styles.label}>{"Teacher Ad Id: " + params.adId}</div>           
                <Input 
                    type="text" 
                    name={"teacherName"} 
                    value={teacherName} 
                    label={"Teacher Name"} 
                    onChange={handleInputChange} 
                    onBlur={handleInputBlur}                   
                    validation={nameValidation} 
                />
                <Input 
                    type="text" 
                    name={"whatsappNo"} 
                    value={whatsappNo} 
                    label={"Whatsapp Number"} 
                    onChange={handleInputChange} 
                    onBlur={handleInputBlur}
                    validation={whatsappValidation} 
                />   
                <MultySelectMultyMenuInput 
                    label={'Grades'}
                    menuOptions={categoryGrades} 
                    selectedOptionIds={selectedGradeIds}
                    setSelectedLabels={getSelectedGradeCategories}
                    setSelectedOptions={setSelectedGradeIds}
                    searchEnabled={true}
                />
                {subjectLoading ? <InputLoader/> :
                <MultySelectInput 
                    label={'Subjects'}
                    options={subjects} 
                    selectedIds={selectedSubjectIds}
                    setSelected={setSelectedSubjectIds}
                    searchEnabled={true}
                    validation={subjectValidation}                    
                    setValidation={setSubjectValidation}
                />}
                <MultySelectInput 
                    label={'Mediums'}                        
                    options={mediums} 
                    selectedIds={selectedMediumIds}
                    setSelected={setSelectedMediumIds}
                />
                <MultySelectInput 
                    label={'Class Types'}                        
                    options={classTypes} 
                    selectedIds={selectedClassTypeIds}
                    setSelected={setSelectedClassTypeIds}
                />                
                <MultySelectInput 
                    label={'Districts'}
                    options={districts} 
                    selectedIds={selectedDistrictIds}
                    setSelected={getSelectedDistricts}
                    searchEnabled={true}                    
                />
                {townLoading ? <InputLoader/> :
                <MultySelectMultyMenuInput 
                    label={'Towns'}
                    menuOptions={districtTowns} 
                    selectedOptionIds={selectedTownIds}
                    setSelectedLabels={setSelectedDistrictIds}
                    setSelectedOptions={setSelectedTownIds}
                    searchEnabled={true}
                />}
                <div>
                    <div style={styles.imageWarning}>{"MAX FILE SIZE: 2 MB"}</div>
                    <img src={imagePreviewUrl} alt="Teacher Ad" onClick={handleImageClick} style={{...styles.imageInput, ...(fileValidation.invalid && styles.imageInvalid)}}/>
                    <input type="file" accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }} ref={fileInputRef}/>
                    {fileValidation.invalid && <div style={styles.invalidMessage}>{fileValidation.invalidMessage}</div>}
                </div> 
                <div style={styles.switch.wrapper} onClick={() => setIsFeatured(!isFeatured)}>
                    <span style={styles.switch.label}>{"Make as featured"}</span>
                    <Switch checked={isFeatured}/>                
                </div> 
                <div style={styles.switch.wrapper} onClick={() => setIsLivePublish(!isLivePublish)}>
                    <span style={styles.switch.label}>{"Publish to live"}</span>
                    <Switch checked={isLivePublish}/>                
                </div>                    
                <Button style={styles.submitButton} onClick={postResponse}>{'Submit'}</Button>
            </>} 
            <PopUpDialog styles={styles.responseDialog} open={openDialog} toggle={closeDialog}>
                <h3 style={styles.responseTitle}>{"success"}</h3>
                <p style={styles.responseMessage}>{"The advertisement was submitted successfully."}</p>                
                <Button style={styles.responseButton} type="button" onClick={closeDialog}>{"OK"}</Button>    
            </PopUpDialog> 
        </div> 
    )
}