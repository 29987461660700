import './App.css';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { StyleContextProvider } from './providers/StyleContextProvider';
import { AuthContextProvider } from './providers/AuthContextProvider';
import { LoginPage } from './pages/LoginPage';
import { HomePage } from './pages/HomePage';
import { WhatsAppPage } from './pages/WhatsAppPage';
import { TeachersPage } from './pages/teachers/TeachersPage';
import { NewAdsPage } from './pages/teachers/NewAdsPage';
import { EditAdsPage } from './pages/teachers/EditAdsPage';
import { useEffect } from 'react';
import { PaymentsPage } from './pages/PaymentsPage';
import { OnlineClassWeeksPage } from './pages/online-classes/OnlineClassWeeksPage';

const AppRoutes = () => {
  let routes = useRoutes([
    { path: "/", element: <HomePage /> },
    { path: "admin/:stage?", element: <LoginPage /> },
    { path: "whatsapp-manager", element: <WhatsAppPage /> },
    { path: "teachers", element: <TeachersPage /> },
    { path: "teachers/newAds/:teacherId?", element: <NewAdsPage /> },
    { path: "teachers/editAds/:adId", element: <EditAdsPage /> },
    { path: "payments", element: <PaymentsPage /> },
    { path: "online-classes/weeks", element: <OnlineClassWeeksPage /> },
  ]);
  return routes;
};

function App() {

  useEffect(() => {
    if(localStorage.getItem('languageCode') === null) {
      localStorage.setItem('languageCode', "SIN");   
    }    
  });
  
  return (
    <StyleContextProvider>
      <AuthContextProvider>
        <BrowserRouter>          
          <AppRoutes />                   
        </BrowserRouter>
      </AuthContextProvider>      
    </StyleContextProvider> 
  );
}

export default App;
