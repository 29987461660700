import { Color } from "../enums/Color"
import { Font } from "../enums/Font"

export const header = {
    mobile: {
        row: {
            display: "flex",
            flexDirection: "row" as "row",
            alignItems: "center" as "center",
            paddingTop: "16px"
        },
        wrapper: {        
            margin: "0",            
            width: "100%",
            height: "16.42vw"
        },
        title: {            
            paddingLeft: "4%", 
            textAlign: "left" as "left",                
            fontSize: "36px",        
            fontFamily: Font.Advanced,        
            letterSpacing: "0px",
            color: Color.Green,            
            cursor: "pointer"
        },        
        label: {             
            textDecoration: "none",
            fontSize: "20px", 
            fontWeight: "bold" as "bold",
            letterSpacing: "0px",
            color: Color.Green,
            cursor: "pointer"
        },         
        mobileMenuButton: {
            backgroundColor: "transparent",
            border: "none",
            marginLeft: "auto",
            marginRight: "4%",
            paddingRight: "0%"            
        },
        desktopMenu: {            
            wrapper: {
                display: "none",
                marginLeft: "auto",
                marginRight: "40px"                
            },
            list: {            
                listStyleType: "none",
                display: "flex",
                flexDirection: "row" as "row"
            },
            listItem: {
                marginRight: "25px"
            }
        },        
        languageSelect: { 
            wrapper: {
                paddingTop: "0"       
            },        
            button: {
                color: Color.White,
                fontSize: "14px"
            },
            labelWrapper: {
                alignItems: "center",
                color: Color.Green
            },
            labelIcon: {
                width: "10px",
                height: "10px",
                fill: Color.Green
            },
            hover: {
                width: "102px",
                top: "26px",
                textAlign: "center" as "center"
            }, 
            item: {
                marginTop: "4px",
                marginBottom: "4px",
                padding: "4px"
            }      
        }
    },
    desktop: {        
        wrapper: {                                                      
            height: "5.29vw"            
        },
        title: {                        
            paddingLeft: "50px",                            
            fontSize: "46px"
        },    
        desktopMenu: {            
            wrapper: {
                display: "inline"
            }
        },
        mobileMenuButton: {
            display: "none"
        },
        loginButton: {
            display: "inline"
        },
        languageSelect: {            
            hover: {
                width: "102px",     
                right: "17%",
                top: "44px"
            }, 
            item: {
                marginTop: "5px",
                marginBottom: "5px",
                padding: "5px"
            }      
        }
    }
}
