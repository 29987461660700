import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const loginPage = {
    mobile: {
        mainWrapper: {
            display: "flex",
            flexDirection: "column"
        },
        leftWrapper: {
            display: "none"
        },
        rightWrapper: { },
        loginForm: {
            width: "90.34vw",
            backgroundColor: Color.White,
            padding: "0 4.83vw",
            marginTop: "14.49vw",
            zIndex: "1"
        },
        loginPassword: {
            marginTop: "8.69vw"
        },
        callbackMessage: {
            height: "auto",
            padding: "4.83vw 3.38vw",
            margin: "3.24vh 0 0 0",
            borderRadius: "2.41vw",
            border: "0.24vw solid " + Color.HarvestGold,
            backgroundColor: Color.LightGold,
            fontFamily: Font.Poppins,
            fontSize: "3.86vw",
            fontWeight: 500,
            color: Color.HarvestGold,
            textAlign: "center"
        },        
        boldTitle: {
            color: Color.DeepBlack,
            fontSize: "4.83vw",
            fontWeight: "bold",
            margin: "7.25vw 0 6.04vw 3.62vw"
        },        
        submitButton: {
            border: "none",
            backgroundColor: Color.Green,
            color: Color.White,
            width: "100%",
            height: "12.08vw",
            borderRadius: "2.41vw",
            fontSize: "4.34vw",
            fontFamily: Font.Default,
            fontWeight: "bold" as "bold",
            textTransform: "uppercase",
            boxShadow: "5px 5px 50px #00000027",
            cursor: "pointer"
        }
    },
    desktop: {
        mainWrapper: { },
        leftWrapper: {
            display: "block"
        },
        rightWrapper: {
            display: "flex",
            margin: "0 7.29vw 0 auto"
        },
        loginForm: {
            width: "29.89vw",
            borderRadius: "0.52vw",
            border: `2px solid ${Color.DeepBlack}`,
            padding: "2.08vw 2.60vw",
            marginTop: 0
        },
        loginPassword: {
            marginTop: "0.78vw"
        },
        callbackMessage: {
            padding: "0.5vw 4.40vw",
            margin: "2.68vh 0 0 0",
            borderRadius: "0.52vw",
            border: "0.05vw solid " + Color.HarvestGold,
            fontSize: "0.94vw"
        },
        boldTitle: {            
            fontSize: "0.94vw",            
            margin: "1.56vw 0 0.78vw 0.52vw"
        },
        submitButton: {
            height: "3.04vw",
            borderRadius: "0.52vw",
            fontSize: "1.04vw",
            marginTop: "2.60vw"        
        }
    }
}