import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StyleContext } from "../providers/StyleContextProvider";
import { AuthContext } from "../providers/AuthContextProvider";
import { LoggedAdmin } from "../models/LoggedAdmin";
import { useMediaQuery } from "react-responsive";
import { Payment } from "../models/Payment";
import { PaymentSearchCriteria } from "../models/PaymentSearchCriteria";
import { changePaymentStatus, getPayments } from "../services/PaymentService";
import { Header } from "../components/shared/Header";
import { Input } from "../components/shared/inputs/Input";
import { NoData } from "../components/shared/NoData";
import { Pagination } from "../components/shared/Pagination";
import { getFormatedDate } from "../helpers/Common";
import { PopUpDialog } from "../components/shared/dialogs/PopUpDialog";
import { Button } from "../components/shared/Button";
import { useAlert } from "../hooks/useAlert";
import { ReactComponent as CloseCircle } from "../assets/svgs/close-circle-ash.svg";

export const PaymentsPage: React.FC = () => {
    const [searchParams] = useSearchParams(); 
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const authContext = useContext(AuthContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("paymentsPage"));  
    const admin: LoggedAdmin | null = authContext.getLoggedAdmin();  
    const page: number = searchParams.get("page") === null ? 1 : +searchParams.get("page")!;
    const [totalPages, setTotalPages] = useState<number>();       
    const pageSize: number = 50;
    const [searchName, setSearchName] = useState<string>("");
    const [name, setName] = useState<string>();
    const [searchEmail, setSearchEmail] = useState<string>("");    
    const [email, setEmail] = useState<string>();    
    const [searchContactNo, setSearchContactNo] = useState<string>("");
    const [contactNo, setContactNo] = useState<string>();    
    const [searchRefferenceNo, setSearchRefferenceNo] = useState<string>("");
    const [refferenceNo, setRefferenceNo] = useState<string>();
    const [date, setDate] = useState<string>();
    const [payments, setPayments] = useState<Payment[]>([]);
    const [selectedPayment, setSelectedPayment] = useState<Payment>();
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" }); 
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();   
    const [hoveredIndex, setHoveredIndex] = useState<number>(-1);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const {Alert, openAlert} = useAlert();
    const [loading, setLoading] = useState<boolean>(true); 

    useEffect(() => {  
        if(admin === null) {            
            navigate('/admin/login');
        }                                   
    }, []);

    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("paymentsPage")); 
    }, [isMobile]);

    useEffect(() => {
        getPaymentList();                                    
    }, [page, name, email, contactNo, refferenceNo, date]);

    async function getPaymentList() {        
        let searchModel: PaymentSearchCriteria = {
            SystemAdminId: admin!.AdminId,
            PageNo: page,
            RecordsPerPage: pageSize,                
            Name: name,
            Email: email,
            ContactNumber: contactNo,
	        RefferenceNumber: refferenceNo,
	        CreatedDate: date ? date.split('T')[0] : undefined
        }
        await getPayments(searchModel, admin!.Token)
        .then((paymentList: Payment[]) => {
            setPayments(paymentList);            
            if(paymentList.length > 0) { 
                let totalPageCount = Math.ceil(paymentList[0].RecordCount / pageSize);            
                setTotalPages(totalPageCount);                
            }  
            else {
                setTotalPages(undefined);
            }  
            setLoading(false);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });                       
    }

    const handlePagination = (pageNumber: number) => {
        searchParams.set("page", pageNumber.toString());                     
        const newUrl = `?${searchParams}`;
        navigate(newUrl);        
    }

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();  
        const value = event.target.value;
        setSearchName(value);

        // Clear previous timeout
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Set new timeout
        const newTimeoutId = setTimeout(() => {
            setName(value.trim());
        }, 1000);
        setTimeoutId(newTimeoutId);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();  
        const value = event.target.value;
        setSearchEmail(value);

        // Clear previous timeout
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Set new timeout
        const newTimeoutId = setTimeout(() => {
            setEmail(value.trim());
        }, 1000);
        setTimeoutId(newTimeoutId);
    };

    const handleContactNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();  
        const value = event.target.value;
        setSearchContactNo(value);

        // Clear previous timeout
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Set new timeout
        const newTimeoutId = setTimeout(() => {
            setContactNo(value.trim());
        }, 1000);
        setTimeoutId(newTimeoutId);
    };

    const handleRefferenceNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();  
        const value = event.target.value;
        setSearchRefferenceNo(value);

        // Clear previous timeout
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Set new timeout
        const newTimeoutId = setTimeout(() => {
            setRefferenceNo(value.trim());
        }, 1000);
        setTimeoutId(newTimeoutId);
    };

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();  
        const value = event.target.value;
        setDate(value);
    };

    const selectPayment = (payment: Payment) => {
        setSelectedPayment(payment);
        setOpenDialog(true);
    };

    async function handlePaymentStatusChange() {
        setOpenDialog(false);
        await changePaymentStatus(selectedPayment!.PaymentId, admin!.AdminId, admin!.Token)
        .then((changed: boolean) => {
            if(changed) {
                setSelectedPayment(undefined);
                openAlert("success", "Payment successfully updated.");
                getPaymentList();
            }
            else {
                openAlert("error", "An error occurred.");
            }
        })
        .catch((error) => {            
            //toErrorPage(error);
        });
    }

    return (   
        <>
            <div style={styles}>
                <Header />     
                <h1 style={styles.heading}>{"Payments"}</h1>           
                <div style={styles.searchWrapper}>
                    <div style={styles.inputWrapper}>
                        <label style={styles.label}>
                            {"Name"}
                        </label>
                        <Input
                            type={"text"}
                            value={searchName}
                            styles={styles.input}
                            onChange={handleNameChange}                                                                                    
                        />
                    </div>
                    <div style={styles.inputWrapper}>
                        <label style={styles.label}>
                            {"Email"}
                        </label>
                        <Input
                            type={"text"}
                            value={searchEmail}
                            styles={styles.input}
                            onChange={handleEmailChange}                                                                                    
                        />
                    </div>
                    <div style={styles.inputWrapper}>
                        <label style={styles.label}>
                            {"Contact Number"}
                        </label>
                        <Input
                            type={"text"}
                            value={searchContactNo}
                            styles={styles.input}
                            onChange={handleContactNoChange}                                                                                    
                        />
                    </div>
                    <div style={styles.inputWrapper}>
                        <label style={styles.label}>
                            {"Refference Number"}
                        </label>
                        <Input
                            type={"text"}
                            value={searchRefferenceNo}
                            styles={styles.input}
                            onChange={handleRefferenceNoChange}                                                                                    
                        />
                    </div>
                    <div style={styles.inputWrapper}>
                        <label style={styles.label}>
                            {"Date"}
                        </label>
                        <Input
                            type={"date"}
                            value={date}
                            styles={styles.input}
                            onChange={handleDateChange}                                                                                    
                        />
                    </div>
                </div>
                <div>                
                    {(payments.length === 0) ? <NoData/> :
                    <table style={styles.table}>
                        <thead>
                            <tr style={styles.table.header}> 
                                <th style={styles.table.idColumn}>{"Id"}</th>                           
                                <th style={styles.table.sourceColumn}>{"Source"}</th>
                                <th style={styles.table.typeColumn}>{"Type"}</th> 
                                <th style={styles.table.userColumn}>{"User"}</th>
                                <th style={styles.table.amountColumn}>{"Amount"}</th>
                                <th style={styles.table.statusColumn}>{"Status"}</th>
                                <th style={styles.table.refferenceColumn}>{"Refference"}</th>
                                <th style={styles.table.dateColumn}>{"Date"}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {payments.map((payment, index) => (
                                <tr 
                                    key={index} 
                                    style={{...styles.table.tr, ...(index === hoveredIndex && styles.table.hoveredTr), ...(!payment.Success && styles.table.failTr)}}
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(-1)}
                                    onClick={() => selectPayment(payment)}
                                >
                                    <td>{payment.PaymentId}</td>
                                    <td>{payment.PaymentSource}</td>
                                    <td>{payment.PaymentTypeName}</td>
                                    <td>
                                        {payment.FullName}<br/>
                                        {payment.Email}<br/>
                                        {payment.ContactNumber}
                                    </td>
                                    <td>{payment.Amount}</td>
                                    <td>{payment.Status}</td>
                                    <td>{payment.RefferenceNumber}</td>
                                    <td>{getFormatedDate(payment.CreatedDate)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>}
                    {(totalPages! > 1) && <Pagination page={page} totalPages={totalPages!} handlePagination={handlePagination}/>}
                </div>
            </div>                 
            <PopUpDialog styles={styles.dialog} open={openDialog} toggle={() => setOpenDialog(!openDialog)}>
                {selectedPayment && <>
                    <div style={styles.dialog.body}>
                        <div style={styles.dialog.idColumn}>{selectedPayment.PaymentId}</div>
                        <div style={styles.dialog.sourceColumn}>{selectedPayment.PaymentSource}</div>
                        <div style={styles.dialog.typeColumn}>{selectedPayment.PaymentTypeName}</div>
                        <div style={styles.dialog.userColumn}>
                            {selectedPayment.FullName}<br/>
                            {selectedPayment.Email}<br/>
                            {selectedPayment.ContactNumber}
                        </div>
                        <div style={styles.dialog.amountColumn}>{selectedPayment.Amount}</div>
                        <div style={styles.dialog.statusColumn}>{selectedPayment.Status}</div>
                        <div style={styles.dialog.refferenceColumn}>{selectedPayment.RefferenceNumber}</div>
                        <div style={styles.dialog.dateColumn}>{getFormatedDate(selectedPayment.CreatedDate)}</div>  
                        <CloseCircle style={styles.dialog.dialogClose} onClick={() => setOpenDialog(!openDialog)} />                       
                    </div>
                    {!selectedPayment.Success && <Button style={styles.dialog.submitButton} onClick={handlePaymentStatusChange}>{"Make payment as successful"}</Button>}                    
                </>}
            </PopUpDialog>
            <Alert />
        </>         
    )
}