import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const dropdown = {
    mobile: { 
        wrapper: {
            width: "45%",
            position: "relative" as "relative",
            display: "inline-block",
            paddingTop: "22px",
            paddingRight: "5%"         
        },        
        button: {
            backgroundColor: "transparent",
            color: Color.Black,
            fontSize: "15px",
            fontFamily: Font.Default,
            border: "none",
            float: "right",
            paddingRight: "0",
            cursor: "pointer"
        },
        labelWrapper: {        
            display: "flex",
            flexDirection: "row" as "row"
        },
        labelIcon: {
            width: "12px",
            height: "12px",
            fill: Color.Black,                
            marginLeft: "5px",
            marginTop: "4px"
        },
        leave: {
            display: "none"            
        },
        hover: {                
            display: "block",
            position: "absolute" as "absolute", 
            width: "168px",           
            height: "auto",  
            padding: "5px",     
            right: "0%",
            top: "60px",                             
            backgroundColor: Color.White,            
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.5)",
            borderRadius: "10px",
            zIndex: 2
        }, 
        item: {
            marginTop: "5px",
            marginBottom: "5px",
            padding: "5px",
            cursor: "pointer",
            hover: {
                backgroundColor: Color.Green,                
                borderRadius: "7px",
                borderBottom : `1px ${Color.White} solid`,
                color : Color.White
            },
            leave: {
                backgroundColor: Color.White,                
                borderBottom : `1px ${Color.MiddleAsh} solid`
            }
        }      
    },
    desktop: {        
        wrapper: {
            width: "180px",         
            paddingTop: "unset",
            paddingRight: "unset",
            textAlign: "center" as "center"        
        },        
        button: {            
            height: "60px",
            fontSize: "16px",
            fontWeight: 600,
            paddingLeft: 0,
            float: "unset"           
        },
        hover: {
            padding: "10px",  
            width: "170px",            
            right: "-3%",
            top: "50px"
        }
    }
}